import React, { useState, useEffect, useMemo } from 'react';
import { useAuth } from '../hooks/useAuth';
import { useLocations } from '../contexts/LocationContext';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList, Legend } from 'recharts';
import { LineChart, Line } from 'recharts';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import Spinner from '../components/common/Spinner';

// Storage key for dashboard settings
const DASHBOARD_SETTINGS_KEY = 'dashboard_settings';

const timeRanges = [
  { value: 'day', label: 'Last Day' },
  { value: 'week', label: 'Last Week' },
  { value: 'month', label: 'Last Month' },
  { value: 'year', label: 'Last 12 Months' },
  { value: 'custom', label: 'Custom Range' }
];

// Helper function to calculate date range based on selected time range
const calculateDateRange = (timeRange) => {
  const endDate = new Date();
  const startDate = new Date();

  switch (timeRange) {
    case 'day':
      startDate.setDate(endDate.getDate() - 1);
      break;
    case 'week':
      startDate.setDate(endDate.getDate() - 7);
      break;
    case 'month':
      startDate.setMonth(endDate.getMonth() - 1);
      break;
    case 'year':
      startDate.setFullYear(endDate.getFullYear() - 1);
      break;
    default:
      startDate.setDate(endDate.getDate() - 7); // Default to a week
  }

  // Format dates as ISO strings (YYYY-MM-DD)
  return {
    startTime: startDate.toISOString().split('T')[0],
    endTime: endDate.toISOString().split('T')[0]
  };
};

const Dashboard = () => {
  const { user } = useAuth();
  const { locations, loading: locationsLoading } = useLocations();

  // Initialize state with values from localStorage or defaults
  const [selectedLocation, setSelectedLocation] = useState(() => {
    try {
      const settings = JSON.parse(localStorage.getItem(DASHBOARD_SETTINGS_KEY));
      return settings?.selectedLocation || 'all';
    } catch (e) {
      return 'all';
    }
  });

  const [ordersTimeRange, setOrdersTimeRange] = useState(() => {
    try {
      const settings = JSON.parse(localStorage.getItem(DASHBOARD_SETTINGS_KEY));
      return settings?.ordersTimeRange || 'week';
    } catch (e) {
      return 'week';
    }
  });

  const [showOrdersCustomDates, setShowOrdersCustomDates] = useState(() => {
    try {
      const settings = JSON.parse(localStorage.getItem(DASHBOARD_SETTINGS_KEY));
      return settings?.ordersTimeRange === 'custom';
    } catch (e) {
      return false;
    }
  });

  const [timeDistributionRange, setTimeDistributionRange] = useState(() => {
    try {
      const settings = JSON.parse(localStorage.getItem(DASHBOARD_SETTINGS_KEY));
      return settings?.timeDistributionRange || 'week';
    } catch (e) {
      return 'week';
    }
  });

  const [showDistributionCustomDates, setShowDistributionCustomDates] = useState(() => {
    try {
      const settings = JSON.parse(localStorage.getItem(DASHBOARD_SETTINGS_KEY));
      return settings?.timeDistributionRange === 'custom';
    } catch (e) {
      return false;
    }
  });

  const [productsTimeRange, setProductsTimeRange] = useState(() => {
    try {
      const settings = JSON.parse(localStorage.getItem(DASHBOARD_SETTINGS_KEY));
      return settings?.productsTimeRange || 'week';
    } catch (e) {
      return 'week';
    }
  });

  const [showProductsCustomDates, setShowProductsCustomDates] = useState(() => {
    try {
      const settings = JSON.parse(localStorage.getItem(DASHBOARD_SETTINGS_KEY));
      return settings?.productsTimeRange === 'custom';
    } catch (e) {
      return false;
    }
  });

  // Separate loading states for each chart
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [timeDistributionLoading, setTimeDistributionLoading] = useState(false);
  const [productsLoading, setProductsLoading] = useState(false);

  const [ordersData, setOrdersData] = useState([]);
  const [timeDistributionData, setTimeDistributionData] = useState([]);
  const [topProductsData, setTopProductsData] = useState([]);

  const [customStartDate, setCustomStartDate] = useState(() => {
    try {
      const settings = JSON.parse(localStorage.getItem(DASHBOARD_SETTINGS_KEY));
      return settings?.customStartDate || '';
    } catch (e) {
      return '';
    }
  });

  const [customEndDate, setCustomEndDate] = useState(() => {
    try {
      const settings = JSON.parse(localStorage.getItem(DASHBOARD_SETTINGS_KEY));
      return settings?.customEndDate || '';
    } catch (e) {
      return '';
    }
  });

  const [showCustomDates, setShowCustomDates] = useState(() => {
    try {
      const settings = JSON.parse(localStorage.getItem(DASHBOARD_SETTINGS_KEY));
      return settings?.timeDistributionRange === 'custom';
    } catch (e) {
      return false;
    }
  });

  // Save settings automatically when they change
  useEffect(() => {
    // Use a debounce to prevent excessive saves
    const timeoutId = setTimeout(() => {
      try {
        const settings = {
          selectedLocation,
          ordersTimeRange,
          timeDistributionRange,
          productsTimeRange,
          customStartDate,
          customEndDate,
          showOrdersCustomDates,
          showDistributionCustomDates,
          showProductsCustomDates
        };

        localStorage.setItem(DASHBOARD_SETTINGS_KEY, JSON.stringify(settings));
      } catch (e) {
        console.error('Failed to save dashboard settings:', e);
      }
    }, 1000);

    // Clean up timeout when component unmounts or dependencies change
    return () => clearTimeout(timeoutId);
  }, [
    selectedLocation,
    ordersTimeRange,
    timeDistributionRange,
    productsTimeRange,
    customStartDate,
    customEndDate,
    showOrdersCustomDates,
    showDistributionCustomDates,
    showProductsCustomDates
  ]);

  // Fetch orders data when location or orders time range changes
  useEffect(() => {
    const fetchOrdersData = async () => {
      if (locationsLoading) return;

      setOrdersLoading(true);
      try {
        const functions = getFunctions();
        const getOrdersStats = httpsCallable(functions, 'getOrdersStats');

        // Calculate date range based on selection
        let startTime, endTime;

        if (ordersTimeRange === 'custom' && customStartDate && customEndDate) {
          startTime = customStartDate;
          endTime = customEndDate;
        } else {
          const dateRange = calculateDateRange(ordersTimeRange);
          startTime = dateRange.startTime;
          endTime = dateRange.endTime;
        }

        const { data } = await getOrdersStats({
          locationId: selectedLocation !== 'all' ? selectedLocation : undefined,
          startTime,
          endTime
        });

        setOrdersData(data.dailyOrders || []);
      } catch (error) {
        console.error('Error fetching orders data:', error);
      } finally {
        setOrdersLoading(false);
      }
    };

    fetchOrdersData();
  }, [selectedLocation, ordersTimeRange, customStartDate, customEndDate, locationsLoading]);

  // Fetch time distribution data when relevant filters change
  useEffect(() => {
    const fetchTimeDistributionData = async () => {
      if (locationsLoading) return;

      setTimeDistributionLoading(true);
      try {
        const functions = getFunctions();
        const getTimeDistribution = httpsCallable(functions, 'getTimeDistribution');

        // Get the user's timezone
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Calculate start and end dates based on selected range
        let startTime, endTime;

        if (timeDistributionRange === 'custom' && customStartDate && customEndDate) {
          startTime = customStartDate;
          endTime = customEndDate;
        } else {
          const dateRange = calculateDateRange(timeDistributionRange);
          startTime = dateRange.startTime;
          endTime = dateRange.endTime;
        }

        const { data } = await getTimeDistribution({
          locationId: selectedLocation !== 'all' ? selectedLocation : undefined,
          startTime,
          endTime,
          timezone
        });

        setTimeDistributionData(data.timeSlots || []);
      } catch (error) {
        console.error('Error fetching time distribution data:', error);
      } finally {
        setTimeDistributionLoading(false);
      }
    };

    fetchTimeDistributionData();
  }, [selectedLocation, timeDistributionRange, customStartDate, customEndDate, locationsLoading]);

  // Format the orders data for better display
  const formattedOrdersData = useMemo(() => {
    if (ordersTimeRange === 'year') {
      // For yearly view, we already have properly formatted week labels
      return ordersData;
    }

    return ordersData.map(item => ({
      ...item,
      date: new Date(item.date).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      }),
    }));
  }, [ordersData, ordersTimeRange]);

  // Format the time distribution data without timezone adjustment
  const formattedTimeDistributionData = useMemo(() => {
    if (!timeDistributionData.length) return [];

    // Create an object to hold all the time slots with their data
    const completeTimeSlots = {};

    // First, convert all the existing time slots from the server
    timeDistributionData.forEach(item => {
      // Parse the time slot (format: "HH:MM")
      const [hourStr, minuteStr] = item.timeSlot.split(':');
      const hour = parseInt(hourStr);
      const minute = parseInt(minuteStr);

      // Format time in 12-hour format
      const period = hour >= 12 ? 'PM' : 'AM';
      const hour12 = hour % 12 || 12; // Convert 0 to 12 for 12-hour format
      const formattedTime = `${String(hour12).padStart(2, '0')}:${minuteStr} ${period}`;

      // Store in our map with the display time as key
      completeTimeSlots[formattedTime] = {
        timeSlot: formattedTime,
        hour,
        minute,
        orders: item.orders,
        sortValue: (hour * 100) + minute
      };
    });

    // Convert to array and sort by time
    const sortedData = Object.values(completeTimeSlots).sort((a, b) => a.sortValue - b.sortValue);

    // Find first and last non-zero indices
    let firstNonZeroIndex = 0;
    while (firstNonZeroIndex < sortedData.length && sortedData[firstNonZeroIndex].orders === 0) {
      firstNonZeroIndex++;
    }

    let lastNonZeroIndex = sortedData.length - 1;
    while (lastNonZeroIndex >= 0 && sortedData[lastNonZeroIndex].orders === 0) {
      lastNonZeroIndex--;
    }

    // Default to all data if no non-zero values found
    if (firstNonZeroIndex > lastNonZeroIndex) {
      return sortedData;
    }

    // Return the trimmed data
    return sortedData.slice(firstNonZeroIndex, lastNonZeroIndex + 1);
  }, [timeDistributionData]);

  // Create a custom date range selector component
  const CustomDateRangeSelector = ({
    showCustomDates,
    customStartDate,
    customEndDate,
    setCustomStartDate,
    setCustomEndDate
  }) => {
    // Use today as default end date if not set
    const today = new Date().toISOString().split('T')[0];

    // Set default start date to 7 days ago if not set
    const defaultStart = () => {
      const date = new Date();
      date.setDate(date.getDate() - 7);
      return date.toISOString().split('T')[0];
    };

    useEffect(() => {
      // Initialize with default dates when first showing
      if (showCustomDates && !customStartDate) {
        setCustomStartDate(defaultStart());
        setCustomEndDate(today);
      }
    }, [showCustomDates, customStartDate, setCustomStartDate, setCustomEndDate]);

    if (!showCustomDates) return null;

    return (
      <div className="mt-2 p-3 border border-gray-200 rounded-md bg-white shadow-sm">
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <label htmlFor="start-date" className="block text-sm font-medium text-gray-700 mb-1">
              Start Date
            </label>
            <input
              type="date"
              id="start-date"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={customStartDate}
              onChange={(e) => {
                const newDate = e.target.value;
                setCustomStartDate(newDate);
              }}
              max={customEndDate || today}
            />
          </div>
          <div>
            <label htmlFor="end-date" className="block text-sm font-medium text-gray-700 mb-1">
              End Date
            </label>
            <input
              type="date"
              id="end-date"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={customEndDate}
              onChange={(e) => {
                const newDate = e.target.value;
                setCustomEndDate(newDate);
              }}
              min={customStartDate}
              max={today}
            />
          </div>
        </div>
      </div>
    );
  };

  // Fetch top selling products data when relevant filters change
  useEffect(() => {
    const fetchTopProductsData = async () => {
      if (locationsLoading) return;

      setProductsLoading(true);
      try {
        const functions = getFunctions();
        const getTopSellingProducts = httpsCallable(functions, 'getTopSellingProducts');

        // Calculate date range based on selection (we'll use the products time range)
        let startTime, endTime;

        if (productsTimeRange === 'custom' && customStartDate && customEndDate) {
          startTime = customStartDate;
          endTime = customEndDate;
        } else {
          const dateRange = calculateDateRange(productsTimeRange);
          startTime = dateRange.startTime;
          endTime = dateRange.endTime;
        }

        const { data } = await getTopSellingProducts({
          locationId: selectedLocation !== 'all' ? selectedLocation : undefined,
          startTime,
          endTime
        });

        // Sort by quantity sold (descending)
        const sortedProducts = data.products.sort((a, b) => b.quantity - a.quantity);
        // Take top 10 products only
        setTopProductsData(sortedProducts.slice(0, 10));
      } catch (error) {
        console.error('Error fetching top products data:', error);
      } finally {
        setProductsLoading(false);
      }
    };

    fetchTopProductsData();
  }, [selectedLocation, productsTimeRange, customStartDate, customEndDate, locationsLoading]);

  // Format and normalize the product data for display
  const formattedProductsData = useMemo(() => {
    return topProductsData.map(product => ({
      ...product,
      // Create a normalized revenue amount (converting cents to dollars)
      revenueInDollars: product.revenue / 100,
      // Format the revenue as currency
      formattedRevenue: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      }).format(product.revenue / 100)
    }));
  }, [topProductsData]);

  // Colors for charts
  const barColor = "#4F46E5";
  const quantityBarColor = "#4F46E5";
  const revenueBarColor = "#10B981"; // Green color for revenue

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <h1 className="text-2xl font-semibold text-gray-900">Welcome to your Dashboard</h1>
      <p className="mt-2 text-gray-600 mb-6">Logged in as: {user?.email}</p>

      {/* Location Selector */}
      <div className="mb-6">
        <label htmlFor="location" className="block text-sm font-medium text-gray-700 mb-2">
          Select Location
        </label>
        <select
          id="location"
          className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          value={selectedLocation}
          onChange={(e) => {
            const newLocation = e.target.value;
            setSelectedLocation(newLocation);
          }}
        >
          <option value="all">All Locations</option>
          {locations.map((location) => (
            <option key={location.id} value={location.id}>
              {location.description}
            </option>
          ))}
        </select>
      </div>

      {/* Orders per Day Chart */}
      <div className="bg-white shadow rounded-lg p-6 mb-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-lg font-medium text-gray-900">Orders per Day</h2>
          <div className="flex flex-col items-end space-y-2">
            <select
              className="rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              value={ordersTimeRange}
              onChange={(e) => {
                const newRange = e.target.value;
                setOrdersTimeRange(newRange);
                setShowOrdersCustomDates(newRange === 'custom');
              }}
            >
              {timeRanges.map((range) => (
                <option key={range.value} value={range.value}>
                  {range.label}
                </option>
              ))}
            </select>
            <CustomDateRangeSelector
              showCustomDates={showOrdersCustomDates}
              customStartDate={customStartDate}
              customEndDate={customEndDate}
              setCustomStartDate={setCustomStartDate}
              setCustomEndDate={setCustomEndDate}
            />
          </div>
        </div>

        {ordersLoading ? (
          <div className="flex justify-center items-center h-64">
            <Spinner className="h-8 w-8 text-indigo-600" />
          </div>
        ) : (
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={formattedOrdersData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  tick={{ fontSize: 12 }}
                  interval={ordersTimeRange === 'year' ? 4 : ordersTimeRange === 'month' ? 5 : 0}
                  angle={ordersTimeRange === 'year' ? -45 : 0}
                  textAnchor={ordersTimeRange === 'year' ? 'end' : 'middle'}
                  height={ordersTimeRange === 'year' ? 70 : 30}
                />
                <YAxis allowDecimals={false} />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="orders"
                  stroke="#4F46E5"
                  strokeWidth={2}
                  name="Orders"
                  connectNulls={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>

      {/* Time Distribution Chart */}
      <div className="bg-white shadow rounded-lg p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-lg font-medium text-gray-900">Orders by Time of Day</h2>
          <div className="flex flex-col items-end space-y-2">
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-500">Showing data in your local timezone</span>
              <div className="relative">
                <select
                  className="rounded-md border border-gray-300 pl-3 pr-10 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm appearance-none"
                  value={timeDistributionRange}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setTimeDistributionRange(newValue);
                    setShowDistributionCustomDates(newValue === 'custom');
                  }}
                >
                  {timeRanges.map((range) => (
                    <option key={range.value} value={range.value}>
                      {range.label}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                  <ChevronDownIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                </div>
              </div>
            </div>
            <CustomDateRangeSelector
              showCustomDates={showDistributionCustomDates}
              customStartDate={customStartDate}
              customEndDate={customEndDate}
              setCustomStartDate={setCustomStartDate}
              setCustomEndDate={setCustomEndDate}
            />
          </div>
        </div>

        {timeDistributionLoading ? (
          <div className="flex justify-center items-center h-64">
            <Spinner className="h-8 w-8 text-indigo-600" />
          </div>
        ) : formattedTimeDistributionData.length === 0 ? (
          <div className="flex justify-center items-center h-64 text-gray-500">
            No data available for the selected time range
          </div>
        ) : (
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={formattedTimeDistributionData}
                margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
                barCategoryGap={1}
                barGap={0}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="timeSlot"
                  tick={{ fontSize: 10 }}
                  interval={0}
                  height={50}
                  angle={90}
                  tickMargin={15}
                  textAnchor="start"
                />
                <YAxis allowDecimals={false} />
                <Tooltip />
                <Bar dataKey="orders" fill={barColor} name="Orders">
                  <LabelList
                    dataKey="orders"
                    position="top"
                    style={{ fontSize: 10, fill: '#333' }}
                    formatter={(value) => value > 20 ? value : ''}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>

      {/* Top Selling Products Chart */}
      <div className="bg-white shadow rounded-lg p-6 mt-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-lg font-medium text-gray-900">Top Selling Products</h2>
          <div className="flex flex-col items-end space-y-2">
            <select
              className="rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              value={productsTimeRange}
              onChange={(e) => {
                const newRange = e.target.value;
                setProductsTimeRange(newRange);
                setShowProductsCustomDates(newRange === 'custom');
              }}
            >
              {timeRanges.map((range) => (
                <option key={range.value} value={range.value}>
                  {range.label}
                </option>
              ))}
            </select>
            <CustomDateRangeSelector
              showCustomDates={showProductsCustomDates}
              customStartDate={customStartDate}
              customEndDate={customEndDate}
              setCustomStartDate={setCustomStartDate}
              setCustomEndDate={setCustomEndDate}
            />
          </div>
        </div>

        {productsLoading ? (
          <div className="flex justify-center items-center h-64">
            <Spinner className="h-8 w-8 text-indigo-600" />
          </div>
        ) : formattedProductsData.length === 0 ? (
          <div className="flex justify-center items-center h-64 text-gray-500">
            No product data available for the selected time range
          </div>
        ) : (
          <div className="h-96">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={formattedProductsData}
                layout="vertical"
                margin={{ top: 5, right: 30, left: 100, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  type="number"
                  tickFormatter={(value) => {
                    // Format X-axis ticks for revenue (divide by 100)
                    if (value > 1000) {
                      return `${(value / 100).toFixed(0)}`;
                    }
                    return value;
                  }}
                />
                <YAxis
                  dataKey="productName"
                  type="category"
                  width={100}
                  tick={{ fontSize: 12 }}
                />
                <Tooltip
                  formatter={(value, name) => {
                    if (name === "revenueInDollars") {
                      return [
                        new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD'
                        }).format(value),
                        'Revenue'
                      ];
                    }
                    return [value, name === "quantity" ? "Units Sold" : name];
                  }}
                />
                <Legend />
                <Bar
                  dataKey="quantity"
                  name="Units Sold"
                  fill={quantityBarColor}
                  barSize={20}
                >
                  <LabelList
                    dataKey="quantity"
                    position="right"
                    style={{ fontSize: 10, fill: '#333' }}
                  />
                </Bar>
                <Bar
                  dataKey="revenueInDollars"
                  name="Revenue"
                  fill={revenueBarColor}
                  barSize={20}
                >
                  <LabelList
                    dataKey="formattedRevenue"
                    position="right"
                    style={{ fontSize: 10, fill: '#333' }}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;