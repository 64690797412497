// dashboard/src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';
import { useAuth } from './hooks/useAuth';
import AuthLayout from './components/layouts/AuthLayout';
import SignupLayout from './components/layouts/SignupLayout';
import DashboardLayout from './components/layouts/DashboardLayout';
import LoginForm from './components/auth/LoginForm';
import SignupForm from './components/auth/SignupForm';
import Dashboard from './pages/Dashboard';
import Users from './pages/Users';
import Locations from './pages/Locations';
import LocationProducts from './pages/LocationProducts';
import Products from './pages/Products';
import ProductForm from './pages/ProductForm';
import Orders from './pages/Orders';
import LocationForm from './pages/LocationForm';
import StorePage from './pages/Store';
import OrderSuccessPage from './pages/SuccessPage';
import { LocationProvider } from './contexts/LocationContext';
import { ProductProvider } from './contexts/ProductContext';
import { UserProvider } from './contexts/UserContext';
import Customers from './pages/Customers';
import OrderReceiptPage from './pages/ReceiptPage';

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return children;
};

const App = () => {
  return (
    <Router
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath: true,
      }}>
      <ProductProvider>
        <Routes>
          <Route
            path="/login"
            element={
              <AuthLayout>
                <LoginForm />
              </AuthLayout>
            }
          />
          <Route
            path="/signup"
            element={
              <SignupLayout>
                <SignupForm />
              </SignupLayout>
            }
          />
          <Route
            path="/dashboard/*"
            element={
              <ProtectedRoute>
                <LocationProvider>
                  <UserProvider>
                    <DashboardLayout>
                      <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/users" element={<Users />} />
                        <Route path="/locations" element={<Locations />} />
                        <Route path="/locations/new" element={<LocationForm />} />
                        <Route path="/locations/:id/products" element={<LocationProducts />} />
                        <Route path="/locations/:id/edit" element={<LocationForm />} />
                        <Route path="/products" element={<Products />} />
                        <Route path="/products/new" element={<ProductForm />} />
                        <Route path="/products/:id/edit" element={<ProductForm />} />
                        <Route path="/orders" element={<Orders />} />
                        <Route path="/customers" element={<Customers />} />

                      </Routes>
                    </DashboardLayout>
                  </UserProvider>
                </LocationProvider>
              </ProtectedRoute>
            }
          />
          <Route path="/store/:storeUrl" element={<StorePage />} />
          <Route path="/store/:storeUrl/success" element={<OrderSuccessPage />} />
          <Route path="/store/:storeUrl/receipt" element={<OrderReceiptPage />} />
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </ProductProvider>
    </Router >
  );
};

export default App;